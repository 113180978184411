import { OPEN_DATA, BIS, CITYPAY, NYC_GOV } from 'data/dataSourceConstants';

/* schema
	[dataSetName]: {
		urlPath: api list enpoint path (may be prefixed with data source)
		dataSet: 'dataSetName' same as key, unique id for ui side stuff
		dataSources: list of data sources for this data set, one of [OPEN_DATA, BIS, CITYPAY, NYC_GOV]
		exportName: data set name used by api excel/pdf export endpoint
		pusherDataSetNames: {
			[dataSource]: name of `datasetName` prop from pusher onUpdate notification
		},
		openIssueCountFields: {
			[dataSource]: property name of open issues count from /api/property/overview/:address endpoint
		},
		detailsUrl: api details endpoint path, if not present then its the same as urlPath
		hasStatus: if false, dataset can't be filtered on open/closed status
	},
*/

export const dataConfig = {
	complaints311: {
		urlPath: '311-complaints',
		dataSet: 'complaints311',
		dataSources: [OPEN_DATA],
		exportName: '311Complaints',
		pusherDataSetNames: {
			[OPEN_DATA]: 'TOOComplaints',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_too_complaints_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-311-complaints',
		},
	},
	dobComplaints: {
		urlPath: 'dob-complaints',
		dataSet: 'dobComplaints',
		dataSources: [OPEN_DATA, BIS],
		exportName: 'complaints',
		pusherDataSetNames: {
			[BIS]: 'BisComplaints',
			[OPEN_DATA]: 'OpenDataDobComplaints',
		},
		openIssueCountFields: {
			[BIS]: 'open_bis_dob_complaints_count',
			[OPEN_DATA]: 'open_complaints_count',
		},
		fileUploadName: {
			[BIS]: 'bis-dob-complaints',
			[OPEN_DATA]: 'open-data-dob-complaints',
		},
	},
	dobViolations: {
		urlPath: 'dob-violations',
		dataSet: 'dobViolations',
		dataSources: [OPEN_DATA, BIS],
		exportName: 'dobViolations',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataDobViolations',
			[BIS]: 'BisDobViolations',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_dob_violations_count',
			[BIS]: 'open_bis_dob_violations_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-dob-violations',
			[BIS]: 'bis-dob-violations',
		},
	},
	ecbViolations: {
		urlPath: 'ecb-violations',
		dataSet: 'ecbViolations',
		dataSources: [OPEN_DATA, BIS],
		exportName: 'ecbViolations',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataEcbViolations',
			[BIS]: 'BisEcbViolations',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_ecb_violations_count',
			[BIS]: 'open_bis_ecb_violations_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-ecb-violations',
			[BIS]: 'bis-ecb-violations',
		},
	},
	jobApplications: {
		urlPath: 'job-applications',
		dataSet: 'jobApplications',
		dataSources: [OPEN_DATA, BIS],
		exportName: 'jobApplications',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataJobApplications',
			[BIS]: 'BisJobApplications',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_job_applications_count',
			[BIS]: 'open_bis_job_applications_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-job-applications',
			[BIS]: 'bis-job-applications',
		},
	},
	dobPermitIssuances: {
		urlPath: 'permit-issuances',
		dataSet: 'permitIssuances',
		dataSources: [OPEN_DATA],
		exportName: 'dobPermitIssuances',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataDobPermitIssuances',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_permit_issuances_count',
		},
		detailsUrl: 'dob-permit-issuances',
		fileUploadName: {
			[OPEN_DATA]: 'open-data-permit-issuances',
		},
	},
	approvedPermits: {
		urlPath: 'approved-permits',
		dataSet: 'approvedPermits',
		dataSources: [OPEN_DATA],
		exportName: 'dobNowBuildApprovedPermits',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataDobNowBuildApprovedPermits',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'od_dob_now_build_approved_permits_count',
		},
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-approved-permits',
		},
	},
	certificatesOfOccupancy: {
		urlPath: 'cos',
		dataSet: 'certificatesOfOccupancy',
		dataSources: [OPEN_DATA],
		exportName: 'dobCOs',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataCertificatesOfOccupancy',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_cos_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-cos',
		},
	},
	dobJobApplications: {
		urlPath: 'dob-now-build-job-application-filings',
		dataSet: 'dobJobApplications',
		dataSources: [OPEN_DATA],
		exportName: 'dobNowBuildJobApplicationFilings',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataDobNowJobApplicationFilings',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_now_build_job_application_filings_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-dob-now-build-job-application-filings',
		},
	},
	dobNowSafetyFacades: {
		urlPath: 'facade-filings',
		dataSet: 'dobNowSafetyFacades',
		dataSources: [OPEN_DATA],
		exportName: 'facadeFilings',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataFacadeFilings',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_now_safety_facades_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-facade-filings',
		},
	},
	dobSignApplicationFilings: {
		urlPath: 'sign-application-filings',
		dataSet: 'dobSignApplicationFilings',
		dataSources: [OPEN_DATA],
		exportName: 'signApplicationFilings',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataSignApplicationFilings',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_sign_application_filings_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-sign-application-filings',
		},
	},
	dobNowSafetyBoilers: {
		urlPath: 'boilers',
		dataSet: 'dobNowSafetyBoilers',
		dataSources: [OPEN_DATA],
		exportName: 'boilers',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataBoilers',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_now_safety_boilers_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-boilers',
		},
	},
	electricalPermitApplications: {
		urlPath: 'electrical-permit-applications',
		dataSet: 'electricalPermitApplications',
		dataSources: [OPEN_DATA],
		exportName: 'electricalPermitApplications',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataElectricalPermitApplications',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_now_electrical_permit_applications_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-electrical-permit-applications',
		},
	},
	elevatorPermitApplications: {
		urlPath: 'elevator-permit-applications',
		dataSet: 'elevatorPermitApplications',
		dataSources: [OPEN_DATA],
		exportName: 'elevatorPermitApplications',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataElevatorPermitApplications',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_dob_now_build_elevator_permit_applications_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-elevator-permit-applications',
		},
	},
	hpdHousingViolations: {
		urlPath: 'hpd-housing-violations',
		dataSet: 'hpdHousingViolations',
		dataSources: [OPEN_DATA],
		exportName: 'housingViolations',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataHPDHousingViolations',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_hpd_housing_violations_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-hpd-housing-violations',
		},
	},
	// hpdHousingComplaints: {
	// 	urlPath: 'hpd-housing-complaints',
	// 	dataSet: 'hpdHousingComplaints',
	// 	dataSources: [OPEN_DATA],
	// 	exportName: 'housingComplaints',
	// 	pusherDataSetNames: {
	// 		[OPEN_DATA]: 'OpenDataHPDHousingComplaints',
	// 	},
	// 	openIssueCountFields: {
	// 		[OPEN_DATA]: 'open_od_hpd_housing_complaints_count',
	// 	},
	// 	fileUploadName: {
	// 		[OPEN_DATA]: 'open-data-hpd-housing-complaints',
	// 	},
	// },
	hpdComplaints: {
		urlPath: 'hpd-complaints',
		dataSet: 'hpdComplaints',
		dataSources: [OPEN_DATA],
		exportName: 'hpdComplaints',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataHPDComplaints',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_od_hpd_complaints_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-hpd-complaints',
		},
	},
	repairVacateOrders: {
		urlPath: 'repair-vacate-orders',
		dataSet: 'repairVacateOrders',
		dataSources: [OPEN_DATA],
		exportName: 'hpdOrdersToRepairVacate',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataRepairVacateOrders',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'repair_vacate_orders_count',
		},
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-repair-vacate-orders',
		},
	},
	standardsApplicationStatuses: {
		urlPath: 'standards-application-statuses',
		dataSet: 'standardsApplicationStatuses',
		dataSources: [OPEN_DATA],
		exportName: 'standardsAppealsApplications',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataStandardsApplicationStatuses',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'standards_application_statuses_count',
		},
		fileUploadName: {
			[OPEN_DATA]: 'open-data-standards-application-statuses',
		},
	},
	fdnyViolations: {
		urlPath: 'fdny/violations',
		dataSet: 'fdnyViolations',
		dataSources: [OPEN_DATA],
		exportName: 'fireActiveViolationOrders',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataFDNYViolations',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_fdny_violations_count',
		},
		detailsUrl: 'fdny-violations',
		fileUploadName: {
			[OPEN_DATA]: 'open-data-fdny-violations',
		},
	},
	fdnyInspections: {
		urlPath: 'fdny/inspections',
		dataSet: 'fdnyInspections',
		dataSources: [OPEN_DATA],
		exportName: 'fireInspections',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataFDNYInspections',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'open_fdny_inspections_count',
		},
		detailsUrl: 'fdny-inspections',
		fileUploadName: {
			[OPEN_DATA]: 'open-data-fdny-inspections',
		},
	},
	fdnyPermits: {
		urlPath: 'fdny-permits',
		dataSet: 'fdnyPermits',
		dataSources: [NYC_GOV],
		exportName: 'fireActivePermits',
		pusherDataSetNames: {
			// [NYC_GOV]: '',
		},
		openIssueCountFields: {
			[NYC_GOV]: 'open_fdny_permits_count',
		},
		fileUploadName: {
			[NYC_GOV]: 'fdny-permits',
		},
	},
	fdnySummaries: {
		urlPath: 'fdny/summaries',
		dataSet: 'fdnySummaries',
		dataSources: [OPEN_DATA],
		exportName: 'fireSummary',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataFDNYSummaries',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'fdny_summaries_count',
		},
		detailsUrl: 'fdny-summaries',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'fdny-summaries',
		},
	},
	citypayTickets: {
		urlPath: 'citypay-tickets',
		dataSet: 'citypayTickets',
		dataSources: [CITYPAY],
		exportName: 'citypayTickets',
		pusherDataSetNames: {
			[CITYPAY]: 'CitypayTickets',
		},
		openIssueCountFields: {
			[CITYPAY]: 'citypay_tickets_count',
		},
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'citypay-tickets',
		},
	},
	taxLienSales: {
		urlPath: 'tax-lien-sales',
		dataSet: 'taxLienSales',
		dataSources: [OPEN_DATA],
		exportName: 'taxLienSales',
		pusherDataSetNames: {
			// [OPEN_DATA]: '',
		},
		openIssueCountFields: {
			// [OPEN_DATA]: 'tax_lien_sales_count',
		},
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-tax-lien-sales',
		},
	},
	acrisLegals: {
		urlPath: 'acris-rpls',
		dataSet: 'acrisLegals',
		dataSources: [OPEN_DATA],
		exportName: 'acrisRpls',
		pusherDataSetNames: {
			// [OPEN_DATA]: '',
		},
		openIssueCountFields: {
			// [OPEN_DATA]: 'tax_lien_sales_count',
		},
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-acris-rpls',
		},
	},
	dobPlaceOfAssembly: {
		urlPath: 'place-of-assemblies',
		dataSet: 'dobPlaceOfAssembly',
		dataSources: [BIS],
		exportName: 'bisPlaceOfAssemblies',
		pusherDataSetNames: {
			// [BIS]: '',
		},
		openIssueCountFields: {
			[BIS]: 'open_bis_place_of_assemblies_count',
		},
		hasStatus: true,
		fileUploadName: {
			[BIS]: 'bis-place-of-assemblies',
		},
	},
	// slaLiquorLicenses: {
	// 	urlPath: 'liquor-licenses',
	// 	dataSet: 'slaLiquorLicenses',
	// 	dataSources: [OPEN_DATA],
	// 	exportName: 'slaLiquorLicenses',
	// 	pusherDataSetNames: {
	// 		// [OPEN_DATA]: '',
	// 	},
	// 	openIssueCountFields: {
	// 		[OPEN_DATA]: 'liquor_licenses_count',
	// 	},
	// 	detailsUrl: 'open-data/liquor-licenses',
	// 	hasStatus: false,
	// 	fileUploadName: {
	// 		[OPEN_DATA]: 'open-data-liquor-licenses',
	// 	},
	// },
	slaLiquorLicenses: {
		urlPath: 'sla/liquor-licenses',
		dataSet: 'slaLiquorLicenses',
		dataSources: [OPEN_DATA],
		exportName: 'odSlaLiquorLicenses',
		pusherDataSetNames: {
			// [OPEN_DATA]: '',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'liquor_licenses_count',
		},
		detailsUrl: 'open-data/sla/liquor-licenses',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-sla-liquor-licenses',
		},
	},
	dosBusinessEntities: {
		urlPath: 'dos-entities',
		dataSet: 'dosBusinessEntities',
		dataSources: [OPEN_DATA],
		exportName: 'dosEntities',
		pusherDataSetNames: {
			[OPEN_DATA]: 'OpenDataDOSEntities',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'od_dos_entities_count',
		},
		detailsUrl: 'open-data/dos-entities',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-dos-entities',
		},
	},
	deedRestrictions: {
		urlPath: 'deed-restrictions',
		dataSet: 'deedRestrictions',
		dataSources: [OPEN_DATA],
		exportName: 'deedRestrictions',
		pusherDataSetNames: {
			[OPEN_DATA]: 'deedRestrictions',
		},
		openIssueCountFields: {
			[OPEN_DATA]: 'od_deed_restrictions_count',
		},
		detailsUrl: 'open-data/deed-restrictions',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-deed-restrictions',
		},
	},
	landmarkBuildings: {
		urlPath: 'landmark-buildings',
		dataSet: 'landmarkBuildings',
		dataSources: [OPEN_DATA],
		exportName: 'landmarkBuildings',
		pusherDataSetNames: {
			[OPEN_DATA]: 'landmarkBuildings',
		},
		openIssueCountFields: {
			// [OPEN_DATA]: '', //no such thing as 'open' landmarks
		},
		detailsUrl: 'open-data/landmark-buildings',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-landmark-buildings',
		},
	},
		landmarkViolations: {
		urlPath: 'landmark-violations',
		dataSet: 'landmarkViolations',
		dataSources: [OPEN_DATA],
		exportName: 'landmarkViolations',
		pusherDataSetNames: {
			// [OPEN_DATA]: 'landmarkViolations',
		},
		openIssueCountFields: {
			// [OPEN_DATA]: '',
		},
		detailsUrl: 'open-data/landmark-violations',
		hasStatus: false,
		fileUploadName: {
			[OPEN_DATA]: 'open-data-landmark-violations',
		},
	},
};