import './AlertsCard.css';

import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";



function AlertsCard() {
	const {
		isLoggedIn,
		propertyData,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		propertyData: state.property.propertyData,
	}));

	let content = <Link className="btn btn-primary mt-5" to={`/sign-in`}>Get alerts</Link>;
	if (!isLoggedIn) {
		content = <Link className="btn btn-primary mt-5" to={`/sign-in`}>Sign up to get alerts</Link>;
 	} else {
 		content = <Link className="btn btn-primary mt-5" to={`/property/${propertyData?.address}/notifications`}>Get alerts</Link>;
 	}

	return (
		<div className="overview-card overview-card-small">
			<div className="overview-card-content">

				<div className="fw-bold">Alerts and Notifications</div>
				<div className="alerts-card-content d-flex justify-content-center">
					{content}
				</div>
			</div>
		</div>
	);
}

export default AlertsCard;
