import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';
import { getReducerName, getDataSourceApiUrlPath, CITYPAY, NYC_GOV } from 'data/dataSourceConstants';
import { sortToString } from 'utils/sort';

const ITEMS_PER_PAGE = 20;

export const getInitialState = () => ({
	isLoading: false,
	propertyId: null,
	filteredStatus: null,
	searchString: '',

	sortField: null,
	sortDirection: null,

	data: [],

	totalItems: null,
	numOpen: null,
	numClosed: null,

	currentPage: null,
	totalPages: null,
	itemsPerPage: null,

	dataSetUrl: null,
	dataSetLastModified: null,
	// dataset_last_modified
});

export const buildQueryParams = (params = {}) => {
	let queryParams = {
		// sort: params.sort || 'id|desc',
		page: params.page || 1,
		per_page: params.perPage || ITEMS_PER_PAGE,
	};

	if (params.status) {
		queryParams.status = params.status;
	}

	if (params.sortString) {
		queryParams.sort = params.sortString;
	}

	if (params.filter && params.filter.length) {
		queryParams.filter = params.filter;
	} else if (params.filter === '') {
		delete queryParams.filter;
	}

	return new URLSearchParams(queryParams).toString();
};


export const apiRequest = (actionType, propertyId, status, searchString, sort) => ({
	type: actionType,
	payload: {
		isLoading: true,
		filteredStatus: status,
		searchString,
		propertyId,
		sortField: sort.field || null,
		sortDirection: sort.direction || null,
	}
});

export const apiSuccess = (actionType, results) => ({
	type: actionType,
	payload: {
		isLoading: false,
		data: results?.data || [],

		totalItems: results.total,
		numOpen: results.num_open,
		numClosed: results.num_closed,
		
		currentPage: results.current_page,
		totalPages: results.last_page,
		itemsPerPage: parseInt(results.per_page),
		dataSetUrl: results.dataset_url,
		dataSetLastModified: results.dataset_last_modified || null,
	}
});

export const apiError = (actionType, results) => ({
	type: actionType,
	payload: {
		isLoading: false,
		data: [],
	}
});


export default function buildGenericDataSetReducer(dataConfig, dataSource) {
	const API_REQUEST = `${getReducerName(dataConfig, dataSource)}_REQUEST`;
	const API_SUCCESS = `${getReducerName(dataConfig, dataSource)}_SUCCESS`;
	const API_ERROR = `${getReducerName(dataConfig, dataSource)}_ERROR`;

	const initialState = getInitialState();

	const setPage = (pageNumber) => (dispatch, getState) => {
		let state = getState()[getReducerName(dataConfig, dataSource)];
		if (pageNumber >= 1 && pageNumber <= state.totalPages) {
			return dispatch(fetchData(
				state.propertyId,
				state.filteredStatus,
				pageNumber,
				state.searchString,
				{ field: state.sortField, direction: state.sortDirection }
			));
		}
	}

	const fetchData = (propertyId, status=null, page=1, searchString='', sort={}) => (dispatch) => {
		let params = buildQueryParams({ status, page, filter: searchString, sortString: sortToString(sort) });
		let url = `${API_URL}/api/property/${propertyId}/${getDataSourceApiUrlPath(dataSource)}/${dataConfig.urlPath}?${params}`;

		if (dataSource === CITYPAY || dataSource === NYC_GOV) {
			//citypay-tickets route doesn't have data source in url path
			url = `${API_URL}/api/property/${propertyId}/${dataConfig.urlPath}?${params}`;
		}

		dispatch(apiRequest(API_REQUEST, propertyId, status, searchString, sort));

		return get({
			dispatch,
			url,
			onSuccess: results => dispatch(apiSuccess(API_SUCCESS, results)),
			onError: error => dispatch(apiError(API_ERROR, error)),
		});
	};

	const silentRefresh = (propertyId) => (dispatch, getState) => {
		let state = getState()[getReducerName(dataConfig, dataSource)];
		let params = buildQueryParams({
			status: state.filteredStatus,
			page: state.currentPage,
			filter: state.searchString,
			sortString: sortToString({
				field: state.sortField,
				direction: state.sortDirection
			}),
		});

		let url = `${API_URL}/api/property/${propertyId}/${getDataSourceApiUrlPath(dataSource)}/${dataConfig.urlPath}?${params}`;

		if (dataSource === CITYPAY || dataSource === NYC_GOV) {
			//citypay-tickets route doesn't have data source in url path
			url = `${API_URL}/api/property/${propertyId}/${dataConfig.urlPath}?${params}`;
		}

		return get({
			dispatch,
			url,
			onSuccess: results => dispatch(apiSuccess(API_SUCCESS, results)),
			onError: error => dispatch(apiError(API_ERROR, error)),
		});
	};

	const reducer = (state = initialState, action) => {
		switch (action.type) {
			case API_REQUEST:
			case API_SUCCESS:
			case API_ERROR:
				return {
					...state,
					...action.payload,
				};
			default:
				return state;
		}
	};

	return {
		reducer,
		fetchData,
		setPage,
		silentRefresh,
	};
}
