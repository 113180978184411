// import { useHistory, Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { removeProperty, fetchPortfolioSettingsByPropertyId } from 'reducers/portfolioSettingsReducer';
import { addPropertyToPortfolio, fetchPortfolio } from 'reducers/portfolioReducer';

 
// import './AddToPortfolioButton.css';

const AddToPortfolioButton = (props) => {
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	let {
		isLoading,
		userId,
		propertyData,
		portfolio,
		isLoadingPortfolio,
		isRemovingProperty,
		isLoggedIn,
	} = useSelector(state => ({
		isLoading: state.property.isLoading || state.address.isLoading,
		userId: state.user.userData?.id,
		propertyData: state.property.propertyData,
		portfolio: state.portfolio.portfolioData?.data || [],
		isLoadingPortfolio: state.portfolio.isLoading || state.portfolioSettings.isLoading,
		isRemovingProperty: state.portfolioSettings.isRemovingProperty,
		isLoggedIn: !!state.user.userData?.id,
	}));
	let isPropertyInPortfolio = !!portfolio.find(portfolioItem => portfolioItem?.property?.id === propertyData?.id);
	let isLoadingAnything = isLoading || isLoadingPortfolio || !propertyData?.id || isRemovingProperty;

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(fetchPortfolio(userId));
		}
	}, [userId, isLoggedIn, dispatch]);

	if (!userId) {
		return null;
	}


	const handleRemoveProperty = (propertyId) => {
		dispatch(removeProperty(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
		});
	}

	const handleAddProperty = (propertyId) => {
		dispatch(addPropertyToPortfolio(propertyId))
		.then((res) => {
			dispatch(fetchPortfolio(userId));
			dispatch(fetchPortfolioSettingsByPropertyId(propertyId));
		});
	}

	let addText = 'Add to Portfolio';
	let removeText = 'Remove from Portfolio';

	if (isLoadingAnything) {
		addText = 'Portfolio';
		removeText = 'Portfolio';
	}

	let togglePortfolioButton = (
		<button className={`btn btn-${isLoadingAnything ? 'secondary' : 'primary'} btn-sm`} disabled={isLoadingAnything} onClick={() => handleAddProperty(propertyData.id)}>
			{isMobile ? (<i className={`bi bi-plus-square`} />) : addText}
		</button>
	);

	if (!isLoadingPortfolio && isPropertyInPortfolio) {
		togglePortfolioButton = (
			<button className={`btn btn-${isLoadingAnything ? 'secondary' : 'danger'} btn-sm`} disabled={isLoadingAnything} onClick={() => handleRemoveProperty(propertyData.id)}>
				{isMobile ? (<i className={`bi bi-dash-square`} />) : removeText}
			</button>
		);
	}


	return (
		togglePortfolioButton
	);	


}

export default AddToPortfolioButton;
