import Pusher from 'pusher-js';

const PUSHER_APP_KEY = 'b8c608fe1acb92d46ba6';
const APP_CLUSTER = 'us2';

//singleton
let pusher = null;

export function initPusher() {
	if (pusher === null) {

		//pusher 3rd party service version
		// pusher = new Pusher(PUSHER_APP_KEY, {
		// 	cluster: APP_CLUSTER,
		// });

		//socketi keith's diy version
		pusher = new Pusher(PUSHER_APP_KEY, {
			cluster: APP_CLUSTER,
			// wsHost: 'sockets.violerts.com',
			wsHost: 'sockets-ec2.violerts.com',
			wsPort: 6001, // Default Soketi port
			enabledTransports: ['ws', 'wss'],
			disableStats: true,
			forceTLS: false,
		});
	}
}

export function unsubscribe(propertyId) {
	if (pusher && propertyId) {
		let channelName = `properties.${propertyId}`;
		pusher.unsubscribe(channelName);
		// console.log("unsubscribe: " + channelName);
	}
}

export function subscribe({ propertyId, onFirstLoadTaskComplete, onUpdate }) {
	if (pusher === null) {
		
		initPusher();
		
		pusher.connection.bind("error", function (error) {
		  console.log('pusher error', error);
		});
		pusher.connection.bind('state_change', function(states) {
			console.log('pusher states', states);
			console.log('pusher states.previous', states.previous);
			console.log('pusher states.current', states.current);
		});
	}

	if (propertyId) {
		let channelName = `properties.${propertyId}`;
		let channel = pusher.subscribe(channelName);

		// console.log("Subscribed to updates: " + channelName);
		// console.log("channel: ", channel);

		if (typeof onFirstLoadTaskComplete === 'function') {
			channel.bind('App\\Events\\PropertyFirstLoadTaskComplete', onFirstLoadTaskComplete);
		}

		if (typeof onUpdate === 'function') {
			channel.bind('App\\Events\\PropertyDatasetUpdated', onUpdate);
		}
	}

}


